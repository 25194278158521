import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function ZendeskGuide() {
  return (
    <Layout
      keywords="Okta sign in guide, Okta IrisAgent documentation"
      title="Sign into IrisAgent with Okta"
      description="Documentation on how to sign into IrisAgent with your Okta account to supercharge your support operations with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/okta-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Okta Sign in Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Okta Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>Okta Sign-in Guide for Zendesk</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent supports SAML-based sign-in flow with Okta. This guide will help you integrate Okta with IrisAgent using SAML.
                    <br/>
                    <br/>
                    <h2>
                    Okta SAML Integration
                    </h2>
                    <br/>
                    <h3>
                    Step 1: Create a new SAML application in Okta
                    </h3>
                    <ol>
                    <li>
                    Follow the <a href="https://help.okta.com/oie/en-us/content/topics/apps/apps_app_integration_wizard_saml.htm" target="_blank">Okta guide</a> to create a new SAML application.
                    </li>
                    <li>
                    Set the following values for the application:
                    <ul>
                        <li>Single sign-on URL: <code>https://frontend-api-server-v2.api.irisagent.com/oauth/okta/saml/acs</code></li>
                        <li>Audience URI (SP Entity ID): <code>https://frontend-api-server-v2.api.irisagent.com/oauth/okta/saml/metadata</code></li>
                        <li>Attribute Statements:</li>
                        <ul>
                            <li><code>email</code> -> <code>user.email</code></li>
                        </ul>
                    </ul>
                    <img
                src="/img/okta_saml.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    </ol>

                    <br/>
                    <h3>
                    Step 2: Provide the metadata to IrisAgent
                    </h3>
                    <br/>
                    <ol>
                        <li>Metadata URL</li>
                        <li>Issuer</li>
                    </ol>

                    <br/>
                    <h3>
                    Step 3: Connect with Zendesk (or any other ticketing system that you use)
                    </h3>
                    <br/>

                    A Zendesk user (with <code>write</code> access) in your team should follow the instructions provided <a href="https://irisagent.com/zd-docs/" target="_blank">here</a> to connect IrisAgent to your Zendesk account.
                        Ensure that this user is assigned to the SAML application of IrisAgent from your Okta dashboard.


                    <br/><br/>
                    <h3>
                    Step 4: Notify IrisAgent
                    </h3>
                    Inform IrisAgent <a href="mailto:contact@irisagent.com" target="_blank">by email</a> once the metadata has been provided and the connection with Zendesk is established.
                    <br/><br/>

                    <br/>
                    <h3>
                    Step 5: Sign in to IrisAgent
                    </h3>
                    After IrisAgent confirms that your account is set up, you can sign in to IrisAgent from the "My Apps" page of your Okta dashboard.

                    <br/><br/>
                    <h3>
                    Step 6: Invite more users to IrisAgent
                    </h3>
                    Your Okta admin can add more users by assigning them to the IrisAgent SAML app in Okta. In addition, please add these users by navigating to "Manage Users" page on <a href="https://web.irisagent.com/" target="_blank">IrisAgent Dashboard.</a>
<br/><br/>
{/*

                    <h2>
                    Details about IrisAgent's Zendesk integration
                    </h2>
                    <br/>
                    <ol>
                        <li>IrisAgent uses <code>read write</code> scopes for the OAuth integration with Zendesk.</li>
                        <li>Out of the box, IrisAgent does not perform any write actions to Zendesk and only reads Zendesk tickets and other data as new tickets come in.</li>
                        <li>IrisAgent explicitly de-duplicates requests sent to Zendesk to ensure that it doesn't exhaust the quota.</li>
                        <li>IrisAgent honors your Zendesk's account rate limits and does not make any unnecessary API calls to Zendesk. If in case, it gets a <code>429 Too Many Requests</code> status code, it backs off and retries as per <a href="https://www.ietf.org/archive/id/draft-polli-ratelimit-headers-02.html" target="_blank">the official RFC instructions</a>.</li>
                        <li>There are optional opt-in features of IrisAgent that leverage the <code>write</code> OAuth scope, such as writing automatic tags and adding private notes with AI insights to Zendesk tickets. Customers have to explicitly opt-in to these features. The write actions are performed safely, monitored via audit logs, and can be disabled easily from the UI. </li>
                    </ol>
<br/>
The above steps will complete the installation of IrisAgent. Please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> once these steps are completed, and our team will start setting up the machine learning models for your account.
*/}
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
